import FloatingLabel from "floating-label-react";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { withStyles } from "@material-ui/core/styles";
import { grey } from "@material-ui/core/colors";
import Radio from "@material-ui/core/Radio";
import RadioGroup from "@material-ui/core/RadioGroup";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import TextareaAutosize from "@mui/base/TextareaAutosize";
import DateFnsUtils from "@date-io/date-fns";
import {
  MuiPickersUtilsProvider,
  KeyboardDatePicker,
} from "@material-ui/pickers";

import WarningIcon from "../images/warning.inline.svg";
import BREAKPOINTS from "../utils/Breakpoints";
import useInterval from "../utils/useInterval";
import Spinner from "./Spinner";

const GreenRadio = withStyles({
  root: {
    color: grey[800],
    "&$checked": {
      color: grey[900],
    },
  },
  checked: {},
})((props) => <Radio color="default" {...props} />);

export const Wrapper = styled.div`
  position: relative;
  margin: 0px auto;
  max-width: 75rem;

  height: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const TitleWrapper = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;

  ${BREAKPOINTS["medium"]} {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
`;

const Fieldset = styled.fieldset`
  border: none;
  padding: 0;
  textarea {
    height: 150px;
    margin-bottom: 10px;
  }
  ${BREAKPOINTS.small} {
    textarea {
      height: 200px;
    }
  }
`;

const SubTitle = styled.p`
  font-family: Chakra;
`;

const Form = styled.form`
  border: none;
`;

const Message = styled.div``;

const WarningImage = styled.span`
  display: inline-block;
  vertical-align: middle;
  margin-bottom: 0px;
`;

const WarningTest = styled.span`
  letter-spacing: 0.08px;
  margin-left: 7px;
  vertical-align: middle;
  color: var(--grey);
`;

const SubmitButton = styled.button`
  border-radius: 2px;
  background-color: var(--white);
  border: 4px solid var(--grey);
  border-radius: 0.3125rem;
  font-family: Chakra;
  font-weight: 900;
  padding: 1rem 6rem;
  color: var(--black);
  font-size: var(--font-size-small);
  cursor: pointer;

  :hover {
    background-color: var(--black);
    color: var(--white);
  }

  :focus {
    background-color: var(--black);
    color: var(--white);
  }

  margin-bottom: 4rem;

  ${BREAKPOINTS["medium"]} {
    margin-bottom: 6.25rem;
  }
`;

const EmailWrapper = styled.div`
  margin-bottom: 3.125rem;
  ${BREAKPOINTS["medium"]} {
  }
`;

const NameWrapper = styled.div`
  margin-bottom: 3.125rem;
`;

const DateWrapper = styled.div`
  margin-bottom: 3.125rem;
`;
const LocationWrapper = styled.div`
  margin-bottom: 6.25rem;
  ${BREAKPOINTS["medium"]} {
    margin-bottom: 0;
  }
`;

const PhoneWrapper = styled.div`
  margin-bottom: 3.125rem;
  ${BREAKPOINTS["medium"]} {
    margin-top: 6.75rem;
  }
`;

const Section = styled.div`
  display: block;

  ${BREAKPOINTS["medium"]} {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(24.5rem, 1fr));
    grid-gap: 3.125rem;
    grid-gap: 8.75rem;
  }
`;

const SectionTitle = styled.p`
  font-family: Chakra;
  color: var(--grey);
  margin-bottom: 1.875rem;
`;

const RadioLabel = styled.div`
  font-family: Chakra;
  color: var(--grey);
  margin-top: -1.5rem;
  margin-bottom: 1rem;
  font-size: calc(var(--font-size-paragraph) / 1.2);
`;

const RadioWrapper = styled(RadioGroup)`
  display: flex;
  flex-direction: inherit !important;
  font-family: Chakra !important;
  margin-bottom: 6.25rem;

  ${BREAKPOINTS["medium"]} {
    margin-bottom: 1.875rem;
  }
`;

const WarningWrapper = styled.div`
  font-family: Chakra !important;
`;

const Picker = styled(KeyboardDatePicker)`
  * {
    font-family: Chakra !important;
    color: var(--black) !important;
    font-size: var(--font-size-paragraph) !important;
  }
`;

const SubmitWrapper = styled.div`
  margin-top: 3.125rem;
`;

const TextArea = styled(TextareaAutosize)`
  background: var(--white);
  border-radius: 0.3125rem;
  font-family: Chakra;
  font-size: var(--font-size-paragraph);
  color: var(--black);
  padding: 0.9375rem;
  margin-bottom: 1.25rem;
  resize: none;
  width: 100%;
  outline: none;
  border: 1px solid var(--black);
`;

const encode = (data) =>
  Object.keys(data)
    .map((key) => `${encodeURIComponent(key)}=${encodeURIComponent(data[key])}`)
    .join("&");

const ContactForm = () => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [pickUpDate, setPickUpDate] = useState(null);
  const [pickUpLocation, setPickUpLocation] = useState("");
  const [dropOffDate, setDropOffDate] = useState(null);
  const [dropOffLocation, setDropOffLocation] = useState("");
  const [carNumber, setCarNumber] = useState("1");
  const [makeAndModel, setMakeAndModel] = useState("");
  const [year, setYear] = useState("");
  const [makeAndModel2, setMakeAndModel2] = useState("");
  const [year2, setYear2] = useState("");
  const [comments, setComments] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [isDataSent, setDataSent] = useState(false);
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (isDataSent) {
      setCount(5);
    }
  }, [isDataSent]);

  useInterval(
    () => {
      setCount((prev) => prev - 1);
    },
    count < 1 ? null : 1000
  );

  const handleSubmit = async (e) => {
    e.preventDefault();
    let data;

    if (
      name &&
      email &&
      phone &&
      pickUpDate &&
      pickUpLocation &&
      dropOffDate &&
      dropOffLocation &&
      carNumber &&
      makeAndModel &&
      year
    ) {
      setDataSent(false);
      setLoading(true);
      try {
        data = await fetch("/", {
          method: "POST",
          headers: { "Content-Type": "application/x-www-form-urlencoded" },
          body: encode({
            "form-name": "contact-form",
            ...{
              name,
              email,
              phone,
              "pick-up-date": pickUpDate,
              "pick-up-location": pickUpLocation,
              "drop-off-date": dropOffDate,
              "drop-off-location": dropOffLocation,
              "car-number": carNumber,
              "make-and-model": makeAndModel,
              year,
              "make-and-model2": makeAndModel2,
              year2,
              comments,
            },
          }),
        });
      } catch (e) {
        console.error(e);
      }
    }
    if (data) {
      setLoading(false);
      setDataSent(true);

      setName("");
      setEmail("");
      setPhone("");
      setPickUpDate(null);
      setPickUpLocation("");
      setDropOffDate(null);
      setDropOffLocation("");
      setCarNumber("1");
      setComments("");
      setMakeAndModel("");
      setYear("");
      setMakeAndModel2("");
      setYear2("");
    }
  };

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils}>
      <form
        name="contact-form"
        netlify="true"
        netlify-honeypot="bot-field"
        hidden
      >
        <input type="text" name="name" />
        <input type="text" name="email" />
        <input type="text" name="phone" />
        <input type="text" name="pick-up-date" />
        <input type="text" name="pick-up-location" />
        <input type="text" name="drop-off-date" />
        <input type="text" name="drop-off-location" />
        <input type="radio" name="car-number" />
        <input type="text" name="make-and-model" />
        <input type="text" name="year" />
        <input type="text" name="make-and-model2" />
        <input type="text" name="year2" />
        <textarea name="comments" />
      </form>
      <Wrapper id="contact">
        <TitleWrapper>
          <h2>Let us contact you</h2>
          <SubTitle>
            Easier for you this way? Please fill out the form below with
            necessary details and we will get back to you in 2 business days.
          </SubTitle>
        </TitleWrapper>
        <Form
          id="contact-form"
          name="contact-form"
          method="POST"
          onSubmit={(e) => handleSubmit(e)}
        >
          <Fieldset>
            <SectionTitle>Personal Information</SectionTitle>
            <Section>
              <div>
                <NameWrapper>
                  <FloatingLabel
                    id="name"
                    name="name"
                    placeholder="Full Name"
                    type="text"
                    value={name}
                    onChange={(evt) => setName(evt.currentTarget.value)}
                  />
                </NameWrapper>
                <EmailWrapper>
                  <FloatingLabel
                    id="email"
                    name="email"
                    placeholder="Email Address"
                    type="email"
                    value={email}
                    onChange={(evt) => setEmail(evt.currentTarget.value)}
                  />
                </EmailWrapper>
              </div>
              <PhoneWrapper>
                <FloatingLabel
                  id="phone"
                  name="phone"
                  placeholder="Your Phone No."
                  type="text"
                  value={phone}
                  onChange={(evt) => setPhone(evt.currentTarget.value)}
                />
              </PhoneWrapper>
            </Section>

            <SectionTitle>Shipment Information</SectionTitle>
            <Section>
              <DateWrapper>
                <Picker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  name="pick-up-date"
                  id="pick-up-date"
                  label="Pick-up Date"
                  value={pickUpDate}
                  onChange={setPickUpDate}
                />
              </DateWrapper>
              <LocationWrapper>
                <FloatingLabel
                  id="pick-up-location"
                  name="pick-up-location"
                  placeholder="Pick-up Location"
                  type="text"
                  value={pickUpLocation}
                  onChange={(evt) => setPickUpLocation(evt.currentTarget.value)}
                />
              </LocationWrapper>
            </Section>

            <Section>
              <DateWrapper>
                <Picker
                  disableToolbar
                  variant="inline"
                  format="MM/dd/yyyy"
                  margin="normal"
                  id="drop-off-date"
                  name="drop-off-date"
                  label="Drop-off Date"
                  value={dropOffDate}
                  onChange={setDropOffDate}
                />
              </DateWrapper>
              <LocationWrapper>
                <NameWrapper>
                  <FloatingLabel
                    id="drop-off-location"
                    name="drop-off-location"
                    placeholder="Drop-off Location"
                    type="text"
                    value={dropOffLocation}
                    onChange={(evt) =>
                      setDropOffLocation(evt.currentTarget.value)
                    }
                  />
                </NameWrapper>
              </LocationWrapper>
            </Section>

            <SectionTitle>Number of Cars</SectionTitle>
            <RadioLabel>
              Currently, our maximum capacity is 2 cars/shipment, running cars
              only{" "}
            </RadioLabel>
            <div>
              <RadioWrapper
                aria-label="car-number"
                name="car-number"
                value={carNumber}
                onChange={(evt) => setCarNumber(evt.currentTarget.value)}
              >
                <FormControlLabel
                  value="1"
                  control={<GreenRadio name="car-number" id="car-number" />}
                  name="car-number"
                  label="1"
                />
                <FormControlLabel
                  value="2"
                  control={<GreenRadio name="car-number" id="car-number" />}
                  name="car-number"
                  label="2"
                />
              </RadioWrapper>
            </div>

            <SectionTitle>Vehicle Details</SectionTitle>
            <Section>
              <DateWrapper>
                <FloatingLabel
                  id="make-and-model"
                  name="make-and-model"
                  placeholder="Make & Model"
                  type="text"
                  value={makeAndModel}
                  onChange={(evt) => setMakeAndModel(evt.currentTarget.value)}
                />
              </DateWrapper>
              <LocationWrapper>
                <FloatingLabel
                  id="year"
                  name="year"
                  placeholder="Year"
                  type="text"
                  value={year}
                  onChange={(evt) => setYear(evt.currentTarget.value)}
                />
              </LocationWrapper>
            </Section>

            {carNumber === "2" && (
              <Section>
                <DateWrapper>
                  <FloatingLabel
                    id="make-and-model2"
                    name="make-and-model2"
                    placeholder="Make & Model for second car"
                    type="text"
                    value={makeAndModel2}
                    onChange={(evt) =>
                      setMakeAndModel2(evt.currentTarget.value)
                    }
                  />
                </DateWrapper>
                <LocationWrapper>
                  <FloatingLabel
                    id="year2"
                    name="year2"
                    placeholder="Year for second car"
                    type="text"
                    value={year2}
                    onChange={(evt) => setYear2(evt.currentTarget.value)}
                  />
                </LocationWrapper>
              </Section>
            )}

            <SectionTitle>Comments</SectionTitle>
            <RadioLabel>
              Not mandatory, but we would appreciate if you have any
            </RadioLabel>
            <Section>
              <TextArea
                id="comments"
                name="comments"
                value={comments}
                onChange={(evt) => setComments(evt.currentTarget.value)}
                maxRows={4}
                aria-label="comments-textarea"
                placeholder="Insert additional information here if needed."
                style={{ height: "9rem" }}
              />
            </Section>

            <SubmitWrapper>
              <Message>
                {count > 0 && count <= 5 && (
                  <SectionTitle>Data was sent successfully!</SectionTitle>
                )}
              </Message>
              <br />

              {isLoading ? (
                <Spinner
                  size="24px"
                  style={{
                    stroke: "var(--color-black)",
                    circle: {
                      stroke: "var(--color-black)",
                    },
                  }}
                />
              ) : (
                <SubmitButton type="submit">SEND</SubmitButton>
              )}
            </SubmitWrapper>
          </Fieldset>
        </Form>
      </Wrapper>
    </MuiPickersUtilsProvider>
  );
};

export default ContactForm;
