import React from "react";
import { Helmet } from "react-helmet";
import { useLocation } from "@reach/router";
import { useStaticQuery, graphql } from "gatsby";

const SEO = ({ title, description, image, article }) => {
  const { pathname } = useLocation();
  const { site } = useStaticQuery(query);

  const { defaultTitle, defaultDescription, siteUrl, defaultImage } =
    site.siteMetadata;

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${pathname}`,
  };

  const canonical = pathname ? `${site.siteMetadata.siteUrl}` : null;

  return (
    <Helmet
      titleTemplate={`%s | ${seo.title}`}
      title={seo.title}
      link={
        canonical
          ? [
              {
                rel: "canonical",
                href: canonical,
              },
            ]
          : []
      }
    >
      <meta name="theme-color" content="#000000" />
      <meta
        name="keywords"
        content="auto transport company, car shipping company, car shipping companies, companies that ship cars, auto transport companies, car transport companies, best car shipping company, best company to ship a car, car moving companies, car hauling companies, auto shipping companies, vehicle transport company, vehicle transportation company, fast car shipping, fast auto shipping, secure auto shipping, reliable auto shipping, enclosed auto transport, deliver cars anywhere, door-to-door auto pick-up, door-to-door auto delivery, door-to-door car pick-up, door-to-door car delivery, dealers car shipping, dealers auto transport"
      />
      <meta
        name="google-site-verification"
        content="zMXvb2txgt3Y1yE8RFhnfL9MZz70ShJg_bWUzkd3Zn4"
      />
      <meta name="description" content={seo.description} />
      <meta name="image" content={seo.image} />
      {seo.url && <meta property="og:url" content={seo.url} />}
      {(article ? true : null) && <meta property="og:type" content="article" />}
      {seo.title && <meta property="og:title" content={seo.title} />}
      {seo.description && (
        <meta property="og:description" content={seo.description} />
      )}
      {seo.image && <meta property="og:image" content={seo.image} />}
      <meta name="twitter:card" content="summary_large_image" />
      {seo.title && <meta name="twitter:title" content={seo.title} />}
      {seo.description && (
        <meta name="twitter:description" content={seo.description} />
      )}
      {seo.image && <meta name="twitter:image" content={seo.image} />}

      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "AutomotiveBusiness",
          name: "Bod Auto Transport & Logistics",
          image: "https://www.bodautotransport.com/images/car2.png",
          "@id": "https://www.bodautotransport.com/#___gatsby",
          description:
            "Fast and Secure Auto Transport across United States. Call us now (929) 330-5585 or email us",
          contactPoint: {
            "@type": "ContactPoint",
            telephone: "+19293305585",
            contactType: "Customer Support",
          },
          url: "https://bodautotransport.com/",
          telephone: "+19293305585",
          address: {
            "@type": "PostalAddress",
            streetAddress: "37898 Eagle Ln Unit 348 Selbyville",
            addressLocality: "Selbyville",
            addressRegion: "DE",
            postalCode: "19975",
            addressCountry: "US",
          },
          openingHoursSpecification: {
            "@type": "OpeningHoursSpecification",
            dayOfWeek: [
              "Monday",
              "Tuesday",
              "Wednesday",
              "Thursday",
              "Friday",
              "Saturday",
              "Sunday",
            ],
            opens: "00:00",
            closes: "23:59",
          },
        })}
      </script>
    </Helmet>
  );
};

export default SEO;

const query = graphql`
  query SEO {
    site {
      siteMetadata {
        defaultTitle: title
        defaultDescription: description
        siteUrl: url
        defaultImage: image
      }
    }
  }
`;
