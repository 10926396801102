import React from "react";
import styled from "styled-components";
import BREAKPOINTS from "../utils/Breakpoints";
import { useStaticQuery, graphql } from "gatsby";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

const Container = styled.div`
  background-color: var(--black);
  height: 100vh;
  min-height: 35rem;
  overflow: hidden;

  ${BREAKPOINTS["small"]} {
    min-height: 55rem;
  }
`;

export const Wrapper = styled.div`
  position: relative;
  margin: 0px auto;
  max-width: 75rem;
  height: 100%;
  padding: 0px;
`;

const MainImageWrapper = styled.div`
  position: relative;
  height: calc(100% - 3.755rem);

  ${BREAKPOINTS["medium"]} {
    height: calc(100% - 5.625rem);
    width: 80%;
    margin: auto;
  }
`;

const Image = styled.div`
  background-color: var(--black);
  height: 100%;
  display: none;
  flex: 1;
  ${BREAKPOINTS["medium"]} {
    display: block;
  }
`;

const CroppedImage = styled.div`
  height: 100%;
  display: block;
  flex: 1;
  ${BREAKPOINTS["medium"]} {
    display: none;
  }
`;

const TextWrapper = styled.div`
  position: absolute;
  text-align: center;
  top: 8rem;
  right: 0;
  left: 0;
  z-index: 3;
  color: var(--white);

  ${BREAKPOINTS["small"]} {
    /* top: 8.125rem; */
    top: 10rem;
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
`;

const FirstHeading = styled.h1`
  margin-bottom: 0.75rem;
  ${BREAKPOINTS["medium"]} {
    margin-bottom: 1.5rem;
  }
`;

const ActionWrapper = styled.div`
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1.25rem;
  z-index: 9;
  background-color: var(--black);
  ${BREAKPOINTS["small"]} {
    bottom: 3rem;
  }
  ${BREAKPOINTS["medium"]} {
    bottom: 5.625rem;
  }
`;

const ButtonWrapper = styled.div`
  margin: auto;
  width: fit-content;
  padding-top: 0.0625rem;
`;

const Button = styled.button`
  text-align: center;
  color: ${(props) => (props.isSecondary ? "var(--white)" : "var(--black)")};
  font-family: Chakra;
  font-weight: bold;
  border: none;
  box-shadow: ${(props) =>
    props.isSecondary ? "0 0 0 0.0625rem var(--white)" : "none"};
  border-radius: 0.25rem;
  background-color: ${(props) =>
    props.isSecondary ? "var(--black)" : "var(--white)"};
  cursor: pointer;
  display: block;
  width: ${(props) => (props.isSecondary ? "calc(95vw - 0.0625rem)" : "95vw")};
  margin-top: 1.25rem;
  height: ${(props) =>
    props.isSecondary ? "calc(2.2rem - 0.0625rem)" : "2.2rem"};

  ${BREAKPOINTS["small"]} {
    margin-right: ${(props) => (props.isSecondary ? 0 : "3.125rem")};
    display: inline-block;
    width: ${(props) =>
      props.isSecondary ? "calc(14.3125rem - 0.0625rem)" : "14.3125rem"};
    height: ${(props) =>
      props.isSecondary ? "calc(2.6875rem - 0.0625rem)" : "2.6875rem"};
    margin-top: 0;
  }
`;

const MainContainer = styled.div`
  height: ${(props) => (props.halfHeight ? "50vh" : "100vh")};
  min-height: ${(props) => (props.halfHeight ? "50vh" : "35rem")};
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
  overflow: hidden;

  ${BREAKPOINTS["small"]} {
    height: 100vh;
    min-height: 55rem;
  }
`;

const Item = ({
  blackBg,
  mainItem,
  image,
  croppedImage,
  halfHeight,
  children,
}) => {
  const data = useStaticQuery(query);
  const heroImage = getImage(data.file);
  const imageContainer = getImage(image);
  const imageContainerCropped = getImage(croppedImage);

  return (
    <>
      {mainItem ? (
        <Container blackBg={blackBg}>
          <Wrapper>
            <TextWrapper itemProp="title">
              <FirstHeading>Ship your car Nationwide</FirstHeading>
              <h2>Fast, Safe, Reliable</h2>
            </TextWrapper>
            <MainImageWrapper>
              <GatsbyImage
                image={heroImage}
                style={{
                  height: "100%",
                  zIndex: 1,
                }}
                alt="hero image"
                itemProp="img"
                loading="lazy"
              />
            </MainImageWrapper>
            <ActionWrapper>
              <ButtonWrapper>
                <a href="tel:+19293305585" itemProp="url">
                  <Button>CALL US NOW</Button>
                </a>
                <a href="#contact" itemProp="url">
                  <Button isSecondary>EMAIL US</Button>
                </a>
              </ButtonWrapper>
            </ActionWrapper>
          </Wrapper>
        </Container>
      ) : (
        <MainContainer halfHeight={halfHeight}>
          {children}
          <Image>
            <GatsbyImage
              image={imageContainer}
              style={{
                height: "100%",
                zIndex: 1,
                backgroundColor: "var(--black)",
              }}
              alt="description-image"
              itemProp="img"
              loading="lazy"
            />
          </Image>

          <CroppedImage>
            <GatsbyImage
              image={imageContainerCropped}
              style={{ height: "100%", zIndex: 1 }}
              alt="description-image"
              itemProp="img"
              loading="lazy"
            />
          </CroppedImage>
        </MainContainer>
      )}
    </>
  );
};

export default Item;

const query = graphql`
  query {
    file(relativePath: { eq: "slice.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData(
          layout: FULL_WIDTH
          formats: [AUTO]
          breakpoints: [300, 400, 500, 600, 960, 1080, 1366, 1920]
          placeholder: BLURRED
        )
      }
    }
  }
`;
