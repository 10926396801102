import React from "react";
import styled from "styled-components";
import BREAKPOINTS from "../utils/Breakpoints";

const Background = styled.div`
  background-color: var(--black);
`;

const Wrapper = styled.div`
  margin: 0px auto;
  max-width: 75rem;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding-top: 4rem;
  padding-left: 1rem;
  padding-right: 1rem;
  background-color: var(--black);

  ${BREAKPOINTS["medium"]} {
    padding-top: 6.25rem;
  }
`;

const Item = styled.div`
  max-width: 22rem;
  margin-bottom: 4rem;
  ${BREAKPOINTS["medium"]} {
    margin-bottom: 6.25rem;
  }
`;

const SubItem = styled.div`
  margin-bottom: 1rem;
  color: var(--white);
  font-family: Chakra;
`;

const AuthorsWrapper = styled.div`
  margin: 0px auto;
  max-width: 75rem;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  padding-left: 1rem;
  padding-right: 1rem;

  * {
    font-family: monospace !important;
    color: darkturquoise !important;
  }
`;

const Footer = () => {
  return (
    <Background>
      <Wrapper>
        <Item style={{ marginRight: "1rem" }}>
          <SubItem style={{ fontWeight: "bold" }} itemProp="title">
            Contact us
          </SubItem>
          <SubItem>
            <a
              href="tel:+19293305585"
              style={{ color: "var(--white)" }}
              itemProp="telephone"
            >
              (929) 330-5585
            </a>{" "}
          </SubItem>
          <SubItem itemProp="email">bodautotransport@gmail.com</SubItem>
        </Item>
        <Item>
          <SubItem style={{ fontWeight: "bold" }} itemProp="title">
            BOD Auto Transport and Logistics
          </SubItem>
          <SubItem>@Copyright 2021</SubItem>
          <SubItem style={{ fontWeight: "bold" }}>All rights reserved</SubItem>
        </Item>
      </Wrapper>
      <AuthorsWrapper>
        <SubItem>
          <a
            href="https://ioana.design"
            style={{ color: "var(--white)" }}
            itemProp="url"
          >
            Designed by
            <span style={{ textDecoration: "underline" }}> Ioana</span>
          </a>{" "}
          & Developed by
          <a
            href="https://victorbaba.com"
            style={{ color: "var(--white)" }}
            itemProp="url"
          >
            <span style={{ textDecoration: "underline" }}> Victor </span>
          </a>{" "}
        </SubItem>
        <SubItem></SubItem>
      </AuthorsWrapper>
    </Background>
  );
};

export default Footer;
