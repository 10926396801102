import React from "react";
import { createGlobalStyle } from "styled-components";
import "normalize.css";
import BREAKPOINTS from "../utils/Breakpoints";
import Seo from "./Seo";
import font from "../fonts/Castoro/Castoro-Regular.ttf";
import chakraFont from "../fonts/Chakra_Petch/ChakraPetch-Medium.ttf";
import Header from "./Header";

const GlobalStyles = createGlobalStyle` 
    :root {
        --line-height: 2.1875rem;
        ${"" /* --line-height-heading: 3; */}
        --font-size-paragraph: 1.25rem;
        --font-size-heading4: 1.563rem;
        --font-size-heading3: 1.953rem;
        --font-size-heading2: 2.441rem;
        --font-size-heading1: 3.052rem;
        --black: #000;
        --white: #fff;
        --grey: rgba(0, 0, 0, 0.6)
    }

    ${BREAKPOINTS["small"]} {
        :root {
        --font-size-paragraph: 1.333rem;
        --font-size-heading4: 1.777rem;
        --font-size-heading3: 2.369rem;
        --font-size-heading2: 3.157rem;
        --font-size-heading1: 4.209rem;
        }
    }
    
    ${BREAKPOINTS["medium"]} {
        :root {
        --font-size-paragraph: 1.414rem;
        --font-size-heading4: 1.999rem;
        --font-size-heading3: 2.827rem;
        --font-size-heading2: 3.998rem;
        --font-size-heading1: 5.653rem;
        }
    }
   
    @font-face {
        font-family: Castoro;
        src: url(${font});
    }
   
    @font-face {
        font-family: Chakra;
        src: url(${chakraFont});
    }

    * {
        box-sizing: border-box;
    }
  
    html {
      scroll-behavior: smooth;
        font-family: Castoro, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
        color: var(--black);
        height: 100%
    }

    body {
        font-size: var(--font-size-paragraph);
        height: 100%;
        font-display: optional;
        ${"" /* line-height: var(--line-height); */}
    }

    h1,h2,h3,h4,h5,h6 {
        font-weight: normal;
        margin: 0;
    }

    h1 {
        font-size: var(--font-size-heading1);
        ${"" /* line-height: var(--line-height-heading); */}
    }
    
    h2 {
        font-size: var(--font-size-heading2);
        ${"" /* line-height: var(--line-height-heading); */}
    }
   
    h3 {
        font-size: var(--font-size-heading3);
        ${"" /* line-height: var(--line-height-heading); */}
    }
    
    h4 {
        font-size: var(--font-size-heading4);
        ${"" /* line-height: var(--line-height-heading); */}
    }

    img {
        max-width: 100%;
    }

    a {
        color: var(--black); 
        text-decoration: none;
    }

    p {margin: 0}


    .floating-label {
        box-sizing: border-box;
        display: block;
        width: 100%;
        position: relative;
        font-family: Chakra;
    }

.floating-label input,
.floating-label textarea {
  display: block;
  background-color: var(--white);
  width: 100%;
  border: 1px solid var(--black);
  border-radius: 4px;
  box-sizing: border-box;
  padding: 15px;

}

.floating-label input:focus,
.floating-label textarea:focus {
  background-color: var(--white);
  padding-top: 23px;
  padding-bottom: 7px;
}

.floating-label.floating input,
.floating-label.floating textarea {
  padding-top: 23px;
  padding-bottom: 7px;
}

.floating-label input:focus + span,
.floating-label textarea:focus + span,
.floating-label.floating span {
  font-family: Chakra;

  color: var(--grey);
  padding: 5px 0 0 15px;
  font-weight: bold;
  font-size: 1rem ;
}

.floating-label input:focus:not(:focus-visible),
.floating-label textarea:focus:not(:focus-visible) {
  outline: none;
}

.floating-label span {
  box-sizing: border-box;
  letter-spacing: 0.1px;
  line-height: calc(var(--line-height ) / 2);
  left: 0;
  padding: 20px 0 20px 25px;
  pointer-events: none;
  position: absolute;
  top: 0;
  transition: font-size 200ms, padding 200ms;
  z-index: 1;
}


.MuiInputLabel-shrink {
  top: 5px !important;
    color: var(--grey) !important;
  }

  .MuiInputLabel-animated {
    color: var(--grey) !important;
  }

  .MuiFormLabel-filled {
    color: var(--grey) !important;
  }

    .MuiPickersCalendarHeader-transitionContainer {
    height: 30px !important;
  }

.MuiPopover-root {
  margin: 0 !important;

  * {
  font-family: Chakra !important;
  font-size: var(--font-size-paragraph) !important;
  }
}
  .MuiPickersDay-daySelected {
    background-color: var(--black) !important;
  }

  .MuiFormLabel-root.Mui-focused {
    color: var(--grey) !important;
  }

  .MuiInput-underline {
    height: 100% !important;
  }

  .MuiInput-underline:after {
    display: none !important;
  }

  
  .MuiInput-underline:before {
    display: none !important;
  }

  .MuiFormControl-marginNormal {
    margin: 0 !important;
    height: 3.625rem !important;
    border: 1px solid black !important;
    border-radius: 4px !important;
    width: 100% !important;
    font-size: var(--font-size-paragraph) !important;
  }

  .MuiInputBase-root {
    font-size: var(--font-size-paragraph) !important;
  }



  .MuiInputLabel-formControl {
    left: 15px !important; 
    color: var(--black) !important;
  }



  .MuiInputBase-input {
    padding-left: 15px !important; 
  }

  .slick-slider {
    overflow: hidden !important;
  }
 `;

const Layout = ({ title, description, image, article, children }) => (
  <div style={{ position: "relative" }}>
    <GlobalStyles />

    <Seo
      title={title}
      description={description}
      image={image}
      article={article}
    />
    <Header />
    {children}
  </div>
);

export default Layout;
