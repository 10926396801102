import * as React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import Item from "../components/Item";
import Section2 from "../components/Section2";
import Section1 from "../components/Section1";
import Item3 from "../components/Item3";
import ContactForm from "../components/ContactForm";
import Footer from "../components/Footer";
import Layout from "../components/Layout";
import RatingsSections from "../components/RatingsSections";

const Home = () => {
  const data = useStaticQuery(query);
  const image = getImage(data.image);

  const imageCropped = getImage(data.imageCropped);

  return (
    <>
      <Layout>
        <Item blackBg mainItem />
        <Item image={image} croppedImage={imageCropped}>
          <Section1 />{" "}
        </Item>
        <Section2 />
        {/* <RatingsSections /> */}
        <Item3></Item3>
        <RatingsSections />
        <ContactForm />
        <Footer />
      </Layout>
    </>
  );
};
export default Home;

const query = graphql`
  query {
    image: file(relativePath: { eq: "day-update2.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData(
          layout: FULL_WIDTH
          formats: [AUTO]
          quality: 50
          placeholder: BLURRED
        )
      }
    }

    imageCropped: file(relativePath: { eq: "day-cropped1.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData(
          layout: FULL_WIDTH
          formats: [AUTO]
          quality: 50
          placeholder: BLURRED
        )
      }
    }
  }
`;
