import React from "react";
import styled from "styled-components";
import BREAKPOINTS from "../utils/Breakpoints";

const SectionContainer = styled.div`
  text-align: center;
  background-color: var(--white);
  padding-top: 4rem;
  padding-bottom: 4rem;

  ${BREAKPOINTS["medium"]} {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
`;

const Heading3 = styled.h3`
  font-family: Chakra;
  margin-top: 0.75rem;

  ${BREAKPOINTS["medium"]} {
    margin-top: 1.5rem;
  }
`;

const Section1 = () => (
  <SectionContainer>
    <h2 itemProp="title">
      Carefree Enclosed Auto <br /> Transport Services
    </h2>
    <Heading3 itemProp="title">
      {" "}
      Top of the line enclosed trailer for vehicle shipping
    </Heading3>
  </SectionContainer>
);

export default Section1;
