import * as React from "react";
import Item from "./Item";
import { useStaticQuery, graphql } from "gatsby";
import { getImage } from "gatsby-plugin-image";

const Item3 = ({ children }) => {
  const data = useStaticQuery(query);
  const image3 = getImage(data.image3);
  const imageCropped3 = getImage(data.imageCropped3);

  return (
    <Item
      image={image3}
      croppedImage={imageCropped3}
      blackBg
      halfHeight
      itemProp="img"
      loading="lazy"
    >
      {children}{" "}
    </Item>
  );
};

const query = graphql`
  query {
    image3: file(relativePath: { eq: "night.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData(
          layout: FULL_WIDTH
          formats: [AUTO]
          breakpoints: [960, 1080, 1366, 1920]
          placeholder: BLURRED
        )
      }
    }

    imageCropped3: file(relativePath: { eq: "night-cropped.jpg" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData(
          layout: FULL_WIDTH
          formats: [AUTO]
          breakpoints: [300, 400, 500, 600]
          placeholder: BLURRED
        )
      }
    }
  }
`;

export default Item3;
