import React from "react";
import styled from "styled-components";
import BREAKPOINTS from "../utils/Breakpoints";
import Lock from "../images/lock.inline.svg";
import Users from "../images/users.inline.svg";
import Briefcase from "../images/briefcase.inline.svg";
import Location from "../images/location.inline.svg";

const Section2Container = styled.div`
  background-color: var(--black);
`;

const Wrapper = styled.div`
  position: relative;
  margin: 0px auto;
  max-width: 75rem;
  height: 100%;
  padding: 0px;
  color: white;
`;

const ItemWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  padding-left: 1.25rem;
  padding-right: 1.25rem;
  align-items: center;
  flex-direction: column;

  ${BREAKPOINTS["small"]} {
    padding-left: 3.25rem;
    padding-right: 3.25rem;
  }
  ${BREAKPOINTS["medium"]} {
    flex-direction: initial;
    justify-content: space-between;
    padding-left: 6.25rem;
    padding-right: 6.25rem;
  }
`;

const Item = styled.div`
  width: 21rem;
  margin-bottom: 3rem;
  display: flex;

  ${BREAKPOINTS["small"]} {
    width: 24rem;
    margin-bottom: 5rem;
  }
  ${BREAKPOINTS["medium"]} {
    width: 22rem;
    display: block;
    margin-right: 2rem;
  }
`;

const Icon = styled.div`
  margin-right: 1rem;
  ${BREAKPOINTS["medium"]} {
    margin-bottom: 1rem;
  }
`;

const Description = styled.h4`
  font-family: Chakra;
  display: flex;
  align-items: center;

  ${BREAKPOINTS["medium"]} {
    display: block;
  }
`;

const Heading = styled.h2`
  text-align: center;
  padding-top: 4rem;
  margin-bottom: 0.75rem;

  ${BREAKPOINTS["medium"]} {
    padding-top: 6.25rem;
    margin-bottom: 1.5rem;
  }
`;

const SubHeading = styled.h3`
  font-family: Chakra;
  text-align: center;
  margin-bottom: 4rem;

  ${BREAKPOINTS["medium"]} {
    margin-bottom: 6.25rem;
  }
`;

const Section2 = () => (
  <Section2Container>
    <Wrapper>
      <Heading itemProp="title">Have your car delivered Anywhere</Heading>
      <SubHeading itemProp="title">
        We offer custom auto transport solutions
      </SubHeading>
      <ItemWrapper>
        <Item>
          <Icon>
            <Lock />
          </Icon>
          <Description itemProp="title">
            Secure door-to-door <br /> pick-up and delivery
          </Description>
        </Item>
        <Item style={{ marginRight: 0 }}>
          <Icon>
            <Users />
          </Icon>
          <Description itemProp="title">
            For Individuals, Dealers <br /> and Manufacturers
          </Description>
        </Item>
      </ItemWrapper>
      <ItemWrapper>
        <Item>
          <Icon>
            <Briefcase />
          </Icon>
          <Description itemProp="title">
            Licensed and Insured <br /> Carriers
          </Description>
        </Item>
        <Item style={{ marginRight: 0 }}>
          <Icon>
            <Location />
          </Icon>
          <Description itemProp="title">National Transport</Description>
        </Item>
      </ItemWrapper>
    </Wrapper>
  </Section2Container>
);

export default Section2;
