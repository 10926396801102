import styled from "styled-components";
import React from "react";

const SvgLoader = styled.svg`
  animation: rotate 1s linear infinite;
  circle {
    animation: dash 1.6s linear infinite;
  }
  @keyframes dash {
    0% {
      stroke-dasharray: 1, 100;
      stroke-dashoffset: 0;
    }
    40% {
      stroke-dasharray: 100, 100;
      stroke-dashoffset: -35;
    }
    100% {
      stroke-dasharray: 100, 130;
      stroke-dashoffset: -124;
    }
  }
`;

function Spinner({ size, ...props }) {
  return (
    <SvgLoader
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 24 24"
      fill="none"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <circle cx="12" cy="12" r="10" />
    </SvgLoader>
  );
}

export default Spinner;
