import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import WhiteSvg from "../images/logo-white.inline.svg";
import { Wrapper } from "./Item";
import BREAKPOINTS from "../utils/Breakpoints";
import PhoneIcon from "../images/phone.inline.svg";

const HeaderWrapper = styled.header`
  width: 100%;
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  height: 4rem;
  z-index: 12;
  background-color: var(--black);

  ${BREAKPOINTS["small"]} {
    height: 6.25rem;
  }
`;

const LogoWrapper = styled.div`
  width: 8rem;

  ${BREAKPOINTS["small"]} {
    width: 10.74125rem;
  }
`;

const Container = styled.div`
  padding-top: 1.2rem;
  padding-left: 0.5rem;
  padding-right: 0.5rem;
  display: flex;
  justify-content: space-between;

  ${BREAKPOINTS["small"]} {
    padding-top: 2rem;
  }
`;

const TelephoneWrapper = styled.div`
  vertical-align: middle;
`;

const Telephone = styled.a`
  font-family: Chakra;
  text-decoration: none;
`;

const TelephoneNumber = styled.span`
  margin-left: 0.625rem;
  color: var(--white);
`;

const NoteParagraphContainer = styled.div`
  position: fixed;
  left: 0;
  right: 0;
  width: 100%;
  background-color: #0f9d58;
  display: block;
`;

const NoteParagraph = styled.p`
  font-family: Chakra;
  font-size: 0.95rem;
  color: var(--white);
  text-align: center;
  width: 100%;
  display: block;
  padding: 0.5rem 0;
`;

const Header = () => {
  return (
    <HeaderWrapper>
      <Wrapper>
        <Container>
          <Link to="/">
            <LogoWrapper>
              <WhiteSvg />
            </LogoWrapper>
          </Link>

          <Telephone href="tel:+19293305585">
            <TelephoneWrapper>
              <PhoneIcon />
              <TelephoneNumber>(929) 330-5585</TelephoneNumber>
            </TelephoneWrapper>
          </Telephone>
        </Container>
      </Wrapper>
      <NoteParagraphContainer>
        <NoteParagraph>
          !! Our business is closed temporarily due to high gas prices, we will
          notify our customers when we will get back !!
        </NoteParagraph>
      </NoteParagraphContainer>
    </HeaderWrapper>
  );
};

export default Header;
