import React, { useState, useEffect } from "react";
import styled from "styled-components";
import Slider from "react-slick";
import { getImage, GatsbyImage } from "gatsby-plugin-image";

import BREAKPOINTS from "../utils/Breakpoints";
import { useStaticQuery, graphql } from "gatsby";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// const reviewsList = [
//   {
//     source: "Christie Finck",
//     body: "Giovanni was absolutely wonderful to work with! Very impressed! Would definitely hire this company again.",
//     body_html:
//       "Giovanni was absolutely wonderful to work with! Very impressed! Would definitely hire this company again.",
//     id: "ChdDSUhNMG9nS0VJQ0FnSUNtdzZyOWlRRRAB",
//     rating: 5,
//     source_link:
//       "https://www.google.com/maps/contrib/115618921579777961072?hl=en-IT&sa=X&ved=2ahUKEwisv-zussX1AhXS-KQKHSayBCIQvvQBegQIARAx",
//     source_image:
//       "https://lh3.googleusercontent.com/a/AATXAJzA50hEMW1ejwH7JJO_QOLaLiLDkfbQ6bwmg-7h=s40-c-c0x00000000-cc-rp-mo-br100",
//     source_id: "115618921579777961072",
//     source_review_count: 2,
//     date: "2 weeks ago",
//     date_utc: "2022-01-08T12:50:26.570Z",
//     position: 1,
//   },
// ];

export const Wrapper = styled.div`
  position: relative;
  margin: 0px auto;
  max-width: 75rem;

  height: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
`;

const TitleWrapper = styled.div`
  padding-top: 4rem;
  padding-bottom: 4rem;

  ${BREAKPOINTS["medium"]} {
    padding-top: 6.25rem;
    padding-bottom: 6.25rem;
  }
`;

const SubTitle = styled.p`
  font-family: Chakra;
`;

const ReviewContainer = styled.div`
  max-width: 21rem;
  border: 1px solid rgba(0, 0, 0, 0.6);
  ${BREAKPOINTS["small"]} {
    max-width: 32.25rem;
  }
  margin-right: 1rem;
  margin-left: 1rem;
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  padding: 1.875rem 1.25rem 1.25rem 1.25rem;
  border-radius: 0.3125rem;
  -webkit-box-shadow: 4px 4px 0px 0px #f4b400;
  box-shadow: 4px 4px 0px 0px #f4b400;
`;

// const ExternalLinkWrapper = styled.span`
//   margin-left: 0.5rem;
// `;

const TopSection = styled.div`
  display: flex;
  margin-bottom: 1.25rem;
  font-style: italic;
`;

const Quote = styled.div`
  font-family: Castoro;
  font-size: 3.665rem;
  ${BREAKPOINTS["small"]} {
    line-height: 5.5rem;
    font-size: 5.665rem;
  }
  margin-right: 1rem;
  line-height: 3.5rem;
`;

const ReviewText = styled.p`
  height: 8.58rem;
  line-height: 1.44rem;
  ${BREAKPOINTS["small"]} {
    line-height: 1.57rem;
    -webkit-line-clamp: 5;
    height: 7.77rem;
  }
  display: -webkit-box;
  -webkit-line-clamp: 6;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
`;

const BottomSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const RatingSection = styled.div`
  font-size: 1rem;
  opacity: 60%;
  font-family: Chakra;
`;

const Bold = styled.b`
  font-weight: 900;
`;

const GoogleImage = styled.div`
  width: 2.625rem;
  height: 2.625rem;
`;

const Link = styled.a`
  padding-left: 0.5rem;
  text-decoration: underline;
  color: rgb(0, 0, 238);
`;

const settings = {
  dots: false,

  slidesToShow: 3,
  slidesToScroll: 1,
  autoplay: true,
  speed: 500,
  autoplaySpeed: 5000,
  centerMode: true,
  adaptiveHeight: true,
  swipeToSlide: true,
  variableWidth: true,
  pauseOnHover: true,
  focusOnSelect: true,
  cssEase: "linear",
  responsive: [
    {
      breakpoint: 1024,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,

        dots: false,
      },
    },
    {
      breakpoint: 600,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,
        initialSlide: 2,

        dots: false,
      },
    },
    {
      breakpoint: 320,
      settings: {
        slidesToShow: 1,
        slidesToScroll: 1,

        dots: false,
      },
    },
  ],
};
const RatingsSection = () => {
  const [isLoading, setIsLoading] = useState(false);
  const data = useStaticQuery(query);
  const googleIcon = getImage(data.reviewImage);

  const reviews = data.reviews;

  useEffect(() => {
    if (!isLoading) {
      setIsLoading(true);
    }
  }, [isLoading]);

  return (
    <div>
      <Wrapper>
        <TitleWrapper>
          <h2>How our customers review us on Google</h2>
          <a
            href="https://search.google.com/local/writereview?placeid=ChIJ85hR5M655KwRUEkMt5XKx6Y&lsig=o"
            target="_blank"
            rel="noreferrer"
          >
            <SubTitle>
              Share your experience with us
              <Link>here</Link>
            </SubTitle>
          </a>
        </TitleWrapper>
      </Wrapper>
      {isLoading && (
        <Slider {...settings}>
          {!!reviews.edges.length &&
            reviews.edges.map((review, index) => (
              <ReviewContainer key={index}>
                <TopSection>
                  <Quote>“</Quote>
                  <ReviewText>
                    {review.node.body && review.node.body.length > 0
                      ? review.node.body
                      : `Customer was in a hurry 🚀. Just a ${review.node.rating}-star rating was left 😌`}
                  </ReviewText>
                </TopSection>

                <BottomSection>
                  <RatingSection>
                    <div>
                      A <Bold>{review.node.rating}-star</Bold> review
                    </div>
                    <div>
                      by <Bold>{review.node.source}</Bold>
                    </div>
                  </RatingSection>
                  <GoogleImage>
                    <GatsbyImage
                      image={googleIcon}
                      style={{
                        height: "100%",
                        width: "100%",
                      }}
                      alt="Google icon"
                      itemProp="img"
                      loading="lazy"
                    />
                  </GoogleImage>
                </BottomSection>
              </ReviewContainer>
            ))}
        </Slider>
      )}
    </div>
  );
};

export default RatingsSection;

const query = graphql`
  query {
    reviewImage: file(relativePath: { eq: "google-icon.png" }) {
      childImageSharp {
        # Specify the image processing specifications right in the query.
        # Makes it trivial to update as your page's design changes.
        gatsbyImageData
      }
    }
    reviews: allGoogleReview {
      edges {
        node {
          source
          body
          rating
        }
      }
    }
  }
`;
